<template>
  <PaymentPage></PaymentPage>
</template>
<script>
import PaymentPage from "../components/PaymentPage.vue";
export default {
  name: "payment",
  components: {
    PaymentPage,
  },
};
</script>
